import React from "react";

import Layout from "../components/layout";
import Searchbar from "../components/searchbar";

const NotFoundPage = () => (
	<Layout title="沒有相關頁面">
		<section className="py-5">
			<div className="flex flex-col-reverse lg:flex-row px-3 lg:px-10">
				<div className="lg:w-3/4">
					<h1 className="pt-5 lg:pt-0 text-3xl lg:text-3xl">
						沒有相關頁面
					</h1>
				</div>
				<div className="item-right lg:w-1/4">
					<Searchbar />
				</div>
			</div>
		</section>
	</Layout>
);

export default NotFoundPage;
